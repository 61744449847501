import React, {useState, useEffect} from 'react'
import { Link } from "gatsby"

import './footer.scss'

const Footer = props => {

    const [windowHeight, setWindowHeight] = useState(0)

    useEffect(() => {
        setWindowHeight(window.innerHeight)
    }, [])

    return (
        <footer style={{ height: windowHeight}}>
            <ul>
                <li><a href="mailto:info@tukantravels.com">Contact</a></li>
                <li>
                    <Link
                        to="/privacy-policy"
                    >
                        Privacy Policy
                    </Link>
                </li>
                <li>
                    <Link
                        to="/terms-and-conditions"
                    >
                        Terms and Conditions
                    </Link>
                </li>
            </ul>
            <span>© {new Date().getFullYear()} Odysee LLC.</span>
        </footer>
    )
}


export default Footer
