import React, { useEffect, useState } from 'react';
// import { Link } from "gatsby"
import { scroller } from 'react-scroll';
import image from '../images/logo.png'

import Banner from '../components/banner';
import Description from '../components/description';
import Footer from '../components/footer';
import './reset.scss';
import _ from 'lodash';

const IndexPage = () => {
    const [scrollPos, setScrollPosition] = useState(0);
	const [scrollAgain, setScrollAgain] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [mobileScroll, setMobileScroll] = useState(0)

    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100%";

        // document.html.style.overflow = "hidden";
        // document.html.style.height = "100%";
    }, [])

    useEffect(() => {
        // initiate the event handler

        window.addEventListener('wheel', handleScroll, {passive: true});
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);
        window.addEventListener('DOMMouseScroll', handleScroll);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
            window.removeEventListener('DOMMouseScroll', handleScroll);
        };
    });

    const handleTouchStart = (e) => {
        const touchobj = e.changedTouches[0];
        const startY = touchobj.pageY;

        setMobileScroll(startY)
    }

    const handleTouchEnd = (e) => {
        const touchobj = e.changedTouches[0];
        const distY = touchobj.pageY - mobileScroll;

        if (distY < 0) {
            if (pageIndex < 4) {
                setScrollPosition(scrollPos + -window.innerHeight)
                setPageIndex(pageIndex + 1)
            }
        } else if (distY > 0) {
            if (pageIndex >= 1) {
                setScrollPosition(scrollPos + window.innerHeight)
                setPageIndex(pageIndex - 1)
            }
        }
    }

    const handleScroll = (e) => {
		const direction = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));

		if (scrollAgain) {
			setScrollAgain(false)

			if (direction < 0) {
				if (pageIndex < 4) {
					setScrollPosition(scrollPos + -window.innerHeight)
					setPageIndex(pageIndex + 1)
				}
			} else if (pageIndex >= 1) {
				setScrollPosition(scrollPos + window.innerHeight)
				setPageIndex(pageIndex - 1)
			}

			setTimeout(() => {
				setScrollAgain(true)
			}, 1250)
		}
	};


    let containerStyle = {
        height: '100%',
        width: '100%',
        position: 'relative',
        transform: `translate3d(0px, ${scrollPos}px, 0px)`,
        transition: `all 1000ms ease`,
    };

    return (
        <>
        <div className='header'>
            <div className="home-nav">
                <img src={image} />
                <h1>Tukan</h1>
                <a href="https://apps.apple.com/us/app/1489419899">Download for iOS</a>
            </div>
        </div>
        <div style={containerStyle}>
            <Banner />
            <Description />
            <Footer />
        </div>
        </>
    );
};

export default IndexPage;
