import React,{useState, useEffect} from 'react';
import { Link } from 'gatsby';
import './banner.scss';

import phoneImage from '../images/iphone.png';

const Banner = () => {
    const [windowHeight, setWindowHeight] = useState(0)

    useEffect(() => {
        setWindowHeight(window.innerHeight)
    }, [])

    return (
        <>
            <div style={{ height: windowHeight}} id='banner1' className="banner">
                <div style={{ height: windowHeight}} className="container">
                    <div className="left">
                        <h1 className="title">
                            Store. <br></br>Share.<br></br> Discover.
                        </h1>
                        {/* <p className='body'>
                        Store all your adventures including photos and venues to share with your friends.
                        Discover what to do on your next adventure by checking out
                        what friends have done on their travels.
                    </p>
                    <a
                        href="https://apps.apple.com/us/app/1489419899"
                    >
                        <img src={appStoreImage} />
                    </a> */}
                    </div>
                    <div className="right">
                        <img src={phoneImage} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Banner;
