import React, {useState, useEffect} from 'react'

import './description.scss'
import Travelog from '../images/Travelog.png'
import Share from '../images/Share.png'
import Search from '../images/Search.png'
import { Element } from 'react-scroll'

const Description = () => {

    const [windowHeight, setWindowHeight] = useState(0)

    useEffect(() => {
        setWindowHeight(window.innerHeight)
    }, [])


    return (
        <div className='description'>
            <Element style={{ height: windowHeight }} name='banner2' id='banner2' className='page store'>
                <div className='container'>
                    <div className='half'>
                        <h1 className='title'>
                            Store
                        </h1>
                        <p>
                            All your travel memories in one convenient place.
                            Store photos and venues in a convenient way to always remember where you've been.
                        </p>
                    </div>
                    <div className='half screen'>
                        <img src={Travelog} />
                    </div>
                </div>
            </Element>
            <Element style={{ height: windowHeight }} name='banner3' id='banner3' className='page share '>
                <div className='container reverse'>
                    <div className='half'>
                        <h1 className='title'>
                            Share
                        </h1>
                        <p>
                            Share the places you've been with friends and family by posting all the adventures on our innovative new feed.
                        </p>
                    </div>
                    <div className='half screen'>
                        <img src={Share} />
                    </div>
                </div>
            </Element>
            <Element style={{ height: windowHeight }} name='banner4' id='banner4' className='page discover'>
                <div className='container'>
                    <div className='half'>
                        <h1 className='title'>
                            Discover
                        </h1>
                        <p>
                            Find friends and discover the cool places they've visited. Who better to get recommendations for your next trip than the people you trust most.
                        </p>
                    </div>
                    <div className='half screen'>
                        <img src={Search} />
                    </div>
                </div>
            </Element>
        </div>
    )
}

export default Description
